import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import PanelScreen from "@/models/PanelScreen";
import JsonTool from "@/services/tool/JsonTool";

export default class PanelScreenService {

    static async postPanelScreen(component: Vue, route: string, title: string) {
        // @ts-ignore
        component.loading = true
        const formData = new FormData()
        formData.set("route", route)
        formData.set("title", title)

        try {
            await component.axios.post(ConstantTool.BASE_URL + "/api/panel-screens", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.dialog = false
            // @ts-ignore
            component.refresh()
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("La sección del panel ha sido registrada correctamente")
        }
    }

    static async getPanelScreen(component: Vue, panelScreens: PanelScreen[]) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/panel-screens", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, PanelScreen)
            panelScreens.splice(0, panelScreens.length)
            list.forEach(p => panelScreens.push(p))
            // @ts-ignore
            component.loading = false
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al ubicar las secciones del panel")
        }
    }

    static async getPanelScreenByUserId(component: Vue, userId: number, panelScreens: PanelScreen[]) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/users/" + userId + "/panel-screens", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, PanelScreen)
            panelScreens.splice(0, panelScreens.length)
            list.forEach(p => panelScreens.push(p))
            // @ts-ignore
            component.loading = false
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al ubicar las secciones del panel")
        }
    }

    static async patchPanelScreenRelateUser(component: Vue, id: number, userId: number) {
        // @ts-ignore
        component.loading = false

        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/panel-screens/" + id + "/users/" + userId + "/relate", null,  {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al agregar la exclusión")
        }
    }

    static async patchPanelScreenUnrelateUser(component: Vue, id: number, userId: number) {
        // @ts-ignore
        component.loading = false

        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/panel-screens/" + id + "/users/" + userId + "/unrelate", null,  {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al eliminar la exclusión")
        }
    }
}